import React from "react"
import SEO from "../components/seo"
import FormAudit from "../components/formAudit/formAudit"

const WebsiteAudit = ({ location }) => {
  return (
    <>
      <SEO
        titleOverride={"Website Audit"}
        pathnameOverride={location.pathname}
      />

      <section className="mb-16 lg:mb-32">
        <div className="container">
          <div className="w-full md:w-2/3 text-center mx-auto mb-8 md:mb-0">
            <h1>Website audit</h1>
            <p className="text-lg mb-8 md:mb-16">Is your Website &amp; Marketing performing to the best it can be?</p>
          </div>
        </div>
        <div className="w-full">
          <FormAudit />
        </div>
      </section>
    </>
  )
}

export default WebsiteAudit